import React from "react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { GlobalStyles } from "src/components/GlobalStyles";
import { Text } from "src/components/Text";
import { breakpoint } from "src/utils/media-query";
import { getImageMetadata } from "src/utils/image";

import Logo from "assets/images/logo.svg";

interface MaintenancePageProps extends PageProps {
  data: GatsbyTypes.MaintenancePageQuery;
}

const MaintenancePage: React.FC<MaintenancePageProps> = ({ data }) => {
  const [ backgroundImage, backgroundImageAlt ] = getImageMetadata(data.strapiMaintenance?.background_image);

  return (
    <FullScreenWrapper>
      <Helmet title="Spring Clean | Dennis Severs' House" />
      <GlobalStyles />
      <PageWrapper>
        {backgroundImage && (
          <FullScreenImage image={backgroundImage} alt={backgroundImageAlt} />
        )}
        <ContentWrapper>
          <Divider />
          <StyledText as="h1">
            {data.strapiMaintenance?.title}
          </StyledText>
          <StyledText
            as="h3"
            style={{
              marginTop: "0.3rem",
              marginBottom: "0.5rem"
            }}
          >
            at
          </StyledText>
          <StyledLogo />
          <StyledText as="small" style={{ marginTop: "4.4rem" }}>
            {data.strapiMaintenance?.description}
          </StyledText>
          <Divider />
        </ContentWrapper>
      </PageWrapper>
    </FullScreenWrapper>
  );
};

export default MaintenancePage;

const FullScreenWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const PageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullScreenImage = styled(GatsbyImage)<GatsbyImageProps>`
  position: absolute!important;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
`;

const ContentWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;

  width: 100%;
  padding: 5rem 2rem;

  ${breakpoint("tabletSP")} {
    width: 16rem;
    padding: 5rem 6rem;
  }
`;

const Divider = styled.div`
  width: 10rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.red};

  &:first-child {
    margin-bottom: 3rem;
    ${breakpoint("tabletSP")} {
      margin-bottom: 4rem;
    }
  }

  &:last-child {
    margin-top: 3rem;
    ${breakpoint("tabletSP")} {
      margin-top: 4rem;
    }
  }
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const StyledLogo = styled(Logo)`
  height: 1.6rem;

  ${breakpoint("mobileLP")} {
    height: 1.8rem;
  }

  ${breakpoint("tabletSP")} {
    height: 2rem;
  }
`;

export const query = graphql`
  query MaintenancePage {
    strapiMaintenance {
      title
      description
      background_image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
